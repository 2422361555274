// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-areas-de-practica-js": () => import("../src/pages/areas-de-practica.js" /* webpackChunkName: "component---src-pages-areas-de-practica-js" */),
  "component---src-pages-articulos-de-interes-js": () => import("../src/pages/articulos-de-interes.js" /* webpackChunkName: "component---src-pages-articulos-de-interes-js" */),
  "component---src-pages-compliance-js": () => import("../src/pages/compliance.js" /* webpackChunkName: "component---src-pages-compliance-js" */),
  "component---src-pages-contacto-js": () => import("../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

